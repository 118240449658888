export const environment = {
    production: false,
    stage: 'develop',
    companyId: 'krc4PsvgVuBKYck4mxen',
    firebaseConfig: {
        apiKey: "AIzaSyAyPH0qZrj8ImH_znCnkJ8FnkFKMZht5V4",
        authDomain: "eule-dev.firebaseapp.com",
        projectId: "eule-dev",
        storageBucket: "eule-dev.appspot.com",
        messagingSenderId: "461739700843",
        appId: "1:461739700843:web:72dfa5368e14320569f72e",
        measurementId: "G-RCLD8C5LXM"
    }
};
